import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../core/common-service/common.service';
import { RemotePriceChangeService } from '../remote-price-change-service/remote-price-change.service';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { MachineDeviceExcelExportItem, MachineDeviceRpcUpdate, MachineDeviceSearchItem, MachineDeviceSearchMetadata, MachineDeviceSearchRequest, MachineDeviceSearchResponse } from '../remote-price-change-service/remote-price-change.model';

import * as moment from 'moment';
import { ISubscription } from 'rxjs/Subscription';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Moment } from 'moment';
import { ExcelService } from '../../core/excel-service/excel.service';
//import { ConversionCategory } from 'src/app/utilities/utilities-service/cat-pog-conv.model';

@Component({
  selector: 'maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  generating: boolean;
  isRefreshingPage: boolean;
  responseMessage: string;
  errorMessage: string;

  machineDeviceInfos:  MachineDeviceSearchItem[] = new Array();
  params = ['routes', 'accounts','machine-search', 'status', 'rpcActivation', 'buttons', 'maintenance'];
  filterPosition = 'in';
  majorAccountId: number;
  accLocList: number[];
  page = 1;
  pageSize = 100;
  totalNumberOfItems = 0;
  sortDirection: string;
  sortColumn: string;
  routeIDList: number[];
  machineDeviceSearchItemSubject: ISubscription;
  machineDeviceSearchMetadataSubject: ISubscription;
  httpErrorResponseSubject: ISubscription;
  isDirty: boolean = false;
  
  view = 'list';
  isMobile = false;
  previousPage = 1;
  freshPage = true;
  restorePreviousFilter = false;


  @ViewChild('operationFilter') operationFilter;

  constructor(private _common: CommonService,
              private _rpcService: RemotePriceChangeService,
              private _filterBarService: FilterBarService,
              private _datePipe: DatePipe,
              private _excel: ExcelService,) 
  {
    this._common.setCurrentPage('RPC Maintenance');            
  }

  ngOnInit() {
    this.generating = false;
    this.resetPage();
    this.setupSubjects();
    this.isDirty = false; //this may not be needed
    this._filterBarService.activateRPCEvent.subscribe(data => {
      this.sendRpcActivations();
    });
    this._filterBarService.selAllEnabled.subscribe(data => {
      this.setAllRpcEnabledFlags();
    });
    this._filterBarService.selAllAutoSend.subscribe(data => {
      //this.setAllRpcAutoSendFlags(); //commented out because this feature is currently disabled.
    });
    this._filterBarService.selectedMajorAcct.subscribe(data => {
      this.majorAccountId = data;
    });
    this._rpcService.onRPCUpdateSuccessEventEmitter.subscribe(data => {
      let response = data as MachineDeviceSearchResponse; 
      response.items.forEach(item => {
        let findItem = this.machineDeviceInfos.find(x=>x.viDeviceId == item.viDeviceId);
        if (findItem != undefined && findItem != null) {
          findItem.rpcAutoSend = item.rpcAutoSend;
          findItem.rpcAutoSendModifiedDateTime = item.rpcAutoSendModifiedDateTime;
          findItem.rpcEnabled = item.rpcEnabled;
          findItem.rpcEnabledDateTime = item.rpcEnabledDateTime;
        }
      });
      this.resetAfterSuccessfulUpdate(data);
    });
    this._rpcService.onRPCUpdateFailureEventEmitter.subscribe(()=> {
      this._filterBarService.setActivateRPCEnabled(true);
    });
  }

  setupSubjects()
  {
    this.machineDeviceSearchItemSubject = this._rpcService.machineDeviceSearchItemSubject.subscribe((data: MachineDeviceSearchItem[]) => {
      console.log("SEARCH REVIEW Result::",data);
      this.machineDeviceInfos = data; 
      this.populateMachineDeviceSearchItems();                
      this.isRefreshingPage = false;
    });

    this.machineDeviceSearchMetadataSubject = this._rpcService.machineDeviceSearchMetadataSubject.subscribe((data:MachineDeviceSearchMetadata) =>
    {
      console.log("SEARCH REVIEW Result Metadata", data);
      this.totalNumberOfItems = data.totalItemCount;
    })

    this.httpErrorResponseSubject = this._rpcService.httpErrorResponseSubject.subscribe((httpErrorResponse:HttpErrorResponse)=> {
      this.errorMessage = `Failure: ${httpErrorResponse.statusText} (Details: ${httpErrorResponse.message})`;
      this.isRefreshingPage = false;
    })
  }

  
 populateMachineDeviceSearchItems() {
   /***
   this.machineDeviceInfos.forEach((item) => {
     this.machineServiceReviewList.push(item);
   });
   ***/

   let totalCount = this.machineDeviceInfos == null || this.machineDeviceInfos.length == 0 ? 0 : -999;
   this.totalNumberOfItems = totalCount;
 }


  onApplyFilters(newFilters) {   
    const searchValue = this._filterBarService.machineSearch.value;
    if (searchValue != undefined && searchValue.length > 0 && isNaN(Number(searchValue))) {
      this._filterBarService.machineSearchError = 'Machine search must be a number';
      return;
    }

    const request = this.buildSearchRequest(newFilters);
    this.search(request);
  }

  private buildSearchRequest(newRequest: boolean) {
    let request = new MachineDeviceSearchRequest();
    request.machineNumber = Number(this._filterBarService.machineSearch.value);
    request.costCenterID = Number(this._common.getOperation().operationNo);
    request.majorAccountId = this.majorAccountId;
    request.accountLocationList = this.getAccLocationArray();
    request.status = String(this._filterBarService.status.value);
    request.rpcActivation = String(this._filterBarService.rpcActivation.value);
    this.page = newRequest ? 1 : this.page;
    request.pageNumber = this.page;
    request.pageSize = this.pageSize;
    request.sortColumn = this.sortColumn;
    request.sortDirection = this.sortDirection;
    request.routeIDList = this.getRouteArray();
    console.log("Search request:",request);
    return request;
  }

  private search(request: MachineDeviceSearchRequest) {
    this.freshPage = false;
    this.isRefreshingPage = true;
    this.errorMessage = '';
    this.responseMessage = '';
    //this._rpcService.previousSearch = request;
    //this.saveRouteSelection();
    this._rpcService.Search(request);
  }

  private exportToExcel() {
    this.responseMessage = "";
    this.errorMessage = "";
    this.isRefreshingPage = true;
    const allItemsFilteredRequest = this.buildSearchRequest(false);
    allItemsFilteredRequest.pageSize = 100000;
    allItemsFilteredRequest.pageNumber = 1;
    console.log(`Export to Excel filter: ${JSON.stringify(allItemsFilteredRequest)}`);
    
    this._rpcService.GetMachineDeviceSearch(allItemsFilteredRequest).subscribe(
      (data: MachineDeviceSearchResponse) => {
        console.log("Excel Export Result::",data);
        this.errorMessage = "";
        let excelItems = transformExcelItems(data.items);

        const dateFormat = "YYYYMMDD";
        let _now: Moment = moment(new Date());
        const fileName = 'RPC Maintenance' + '_' + _now.format(dateFormat);
          this._excel.exportAsExcelFile(excelItems, fileName);
        this.isRefreshingPage = false;
      }),
      (error => {
        console.log("Excel Export fail");
        this.errorMessage = `An error has occurred.  Please try again.  Message=${error}`;
        this.isRefreshingPage = false;
      });
  } // end exportToExcel


  private getRouteArray() {
    this.routeIDList = new Array();
    for (let i = 0; i < this._filterBarService.routes.value.length; i++) {
    if(this._filterBarService.routes.value[i].selected) {  
     this.routeIDList.push(this._filterBarService.routes.value[i].routeID);
     }
    }
    return this.routeIDList;
  }

  private getAccLocationArray() { 
    this.accLocList = new Array();
    if (this._filterBarService.accountLocationList.value != null) {
      for (let i = 0; i < this._filterBarService.accountLocationList.value.length; i++) {
        if(this._filterBarService.accountLocationList.value[i].selected) {
          this.accLocList.push(this._filterBarService.accountLocationList.value[i].id);
        }
      }
    }
   return this.accLocList;
 }

 onResetFilters(reset) {
  this.resetPage();
}

private resetPage() {
  this.isRefreshingPage = true;
  this._rpcService.Initialize();
  this._filterBarService.ResetFilterBar();
  this.isRefreshingPage = false;
}

onChangeView(view) {
  this.view = view;
  if (this.isMobile) {
    this.filterPosition = 'out';
  } else {
    this.filterPosition = view === 'grid' ? 'out' : 'in';
  }
}

getPageInfo() {
  return this.totalNumberOfItems;
}

getCompatibleColumnMessage(e: boolean){
  return e ? 'Yes' : 'No';
}

markAsDirty(e: number, f: string){
  this.isDirty = true;
  this._filterBarService.setTableAsDirty(true);
  let indexToUpdate = this.machineDeviceInfos.findIndex(item => item.viDeviceId === e);
  this.machineDeviceInfos[indexToUpdate].rowIsDirty = true;
  if (f === 'enabled'){
    this.machineDeviceInfos[indexToUpdate].rpcEnabled = !this.machineDeviceInfos[indexToUpdate].rpcEnabled;
  }

  if (f === 'autosend'){
    this.machineDeviceInfos[indexToUpdate].rpcAutoSend = !this.machineDeviceInfos[indexToUpdate].rpcAutoSend;
  }
  
  this.machineDeviceInfos = Object.assign([], this.machineDeviceInfos);
}

selectOperationStatus($event, status){
  //not sure what this is supposed to to.
}

blankOutMinDate(e: Date){
  const minDateTime = new Date('0001-01-01T00:00:00').getTime();
  var dateTime = new Date(e);
  if (dateTime.getTime() === minDateTime)
    return '';
  else
    return moment(dateTime).format ("MM/DD/YYYY h:mm A");
}

sendRpcActivations(){
  this.errorMessage = ''; this.responseMessage = '';
  let dirtyRows = this.machineDeviceInfos.filter(x => x.rowIsDirty);
  let updatesToSend = new Array<MachineDeviceRpcUpdate>();
  dirtyRows.forEach(x => updatesToSend.push(this.makeUpdateObject(x)));
  this._filterBarService.setActivateRPCEnabled(false);
  this._rpcService.sendUpdates(updatesToSend);
}

private makeUpdateObject(convertMe: MachineDeviceSearchItem){
  let result = new MachineDeviceRpcUpdate();
  result.viDeviceId = convertMe.viDeviceId;
  result.vendorCode = convertMe.vendorCode;
  result.rpcEnabled = convertMe.rpcEnabled;
  result.rpcAutoSend = convertMe.rpcAutoSend;
  return result;
}

resetAfterSuccessfulUpdate(data:any) {
  this.isDirty = false;
  this._filterBarService.setTableAsDirty(false);
  this.machineDeviceInfos.filter(x=>x.rowIsDirty).forEach(x=>x.rowIsDirty=false);
  this._filterBarService.setActivateRPCEnabled(true);
}

pageChanged() {
  this.onApplyFilters(false);
  window.scrollTo(0, 0);
}

setAllRpcEnabledFlags() {
  let checkbox = <HTMLInputElement> document.getElementById('selectAllEnabledCheckbox');
  let isChecked = checkbox.checked;
  let updateMade: boolean = false;
  if (this.machineDeviceInfos != null && this.machineDeviceInfos.length > 0){
    this.machineDeviceInfos.forEach(x => {
      if (x.rpcCompatible && !x.rpcEnabled && isChecked) {
        x.rpcEnabled = true;
        x.rowIsDirty = true;
        updateMade = true;
      }
      else if (x.rpcEnabled && !isChecked) {
        x.rpcEnabled = false;
        x.rowIsDirty = true;
        updateMade = true;
      }
    });
  }
  if (updateMade){
    this.isDirty = true;
    this._filterBarService.setTableAsDirty(true);
  }
}

setAllRpcAutoSendFlags() {
  let checkbox = <HTMLInputElement> document.getElementById('selectAllAutoSendCheckbox');
  let isChecked = checkbox.checked;
  let updateMade: boolean = false;
  if (this.machineDeviceInfos != null && this.machineDeviceInfos.length > 0){
    this.machineDeviceInfos.forEach(x => {
      if (x.rpcEnabled && !x.rpcAutoSend && isChecked) {
        x.rpcAutoSend = true;
        x.rowIsDirty = true;
        updateMade = true;
      }
      else if (x.rpcAutoSend && !isChecked) {
        x.rpcAutoSend = false;
        x.rowIsDirty = true;
        updateMade = true;
      }
    });
  }
  if (updateMade){
    this.isDirty = true;
    this._filterBarService.setTableAsDirty(true);
  }
}

getStatusIcon(e: any) {
  if (e === 'COMPLETED')
    return '\u2713';
  
  if (e === 'ERROR')
    return '\u274c';

  if (e === 'IN_PROCESS')
    return '\u25ef';

  return '';
}

getStatusColumnClass(code: string){
  switch (code) {
    case 'COMPLETED':
      return 'text-center greenCheck';
    case 'ERROR':
      return 'text-center redX';
    case 'IN_PROCESS':
      return 'text-center blueCircle';
    default:
      return 'text-center';
  }
}

sortOpenOperationStatuses(sortAsc, columnName, boolValue) {
  this.sortColumn = columnName;
  this.sortDirection = sortAsc ? 'asc' : 'desc';
  let request : MachineDeviceSearchRequest = this.buildSearchRequest(true);
  this.search(request);
}

}

function transformExcelItems(searchItems: MachineDeviceSearchItem[]): MachineDeviceExcelExportItem[] {
  let excelItems:MachineDeviceExcelExportItem[] = []

  for (let index = 0; index < searchItems.length; index++) {
    const item = searchItems[index];
    const dateTimeFormat = "MM/DD/YYYY h:mm A";
    const dateFormat = "MM/DD/YYYY";
    const nullishDate = "0001-01-01T00:00:00";

    let excelItem = new MachineDeviceExcelExportItem();
    excelItem.Account = item.accountName;
    excelItem.Location = item.locationName;
    excelItem.Route = item.routeNumber;
    excelItem.Machine = item.machineNumber;
    excelItem.MachineType = item.dispenseMethodName;
    excelItem.POGEffective = moment(item.lastpogEffectiveDate).format(dateFormat); 
    excelItem.DeviceNo = item.deviceNo;
    excelItem.RPCCompatible = item.rpcCompatible ? "Yes" : "No";
    excelItem.RPCEnabled = item.rpcEnabled ? "Yes" : "No";
    excelItem.RPCEnabledDate = (item.rpcEnabledDateTime.toString() == nullishDate ? null : moment(item.rpcEnabledDateTime).format(dateTimeFormat)); 
    excelItem.RPCAutoSend = item.rpcAutoSend ? "Yes" : "No";
    excelItem.RPCAutoSendSetDate = (item.rpcAutoSendModifiedDateTime.toString() == nullishDate ? null : moment(item.rpcAutoSendModifiedDateTime).format(dateTimeFormat));
    excelItem.Status = item.statusCode;
    excelItem.RPCSendDate = (item.rpcSendDate.toString() == nullishDate ? null : moment(item.rpcSendDate).format(dateTimeFormat));
    excelItem.ErrorMessage = item.errorMessage;

    excelItems.push(excelItem);

  }

  return excelItems;
}

