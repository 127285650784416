import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonService } from '../../../core/common-service/common.service';
import { SecurityService } from '../../../core/security/security.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OperationsService } from '../../operation-service/operations.service';
import { ExcelService } from '../../../core/excel-service/excel.service';
import { FiscalClosingValidationItem, FiscalCloseDetail, FiscalCloseWarehouses } from '../../operation-service/fiscal-closing.model';
import { RoleGuardService } from '../../../core/role-guard/role-guard.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
// import { ignoreElements } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-closing-detail',
  templateUrl: './closing-detail.component.html',
  styleUrls: ['./closing-detail.component.scss']
})
export class ClosingDetailComponent implements OnInit, OnDestroy {

  loading = false;
  validationRan = false;
  fiscalCalendarID: number;
  cashlessRunning = false;
  cashlessRan = false;
  cashlessHasError = false;
  showBackWarning = false;
  closingErrorMessage: string;
  closingDetails: FiscalCloseDetail = new FiscalCloseDetail();
  warehouses: FiscalCloseWarehouses[];
  closingWarnings: FiscalClosingValidationItem[] = [];
  closingErrors: FiscalClosingValidationItem[] = [];
  closingIssuesForDisplay: FiscalClosingValidationItem[] = [];
  hasCashlessErrors = false;
  disableClose = false;
  disableValidate = false;
  disableCashless = false;
  canValidate = this._roleGuard.hasRole('VIEW_FISCAL_WEEK_CLOSED');
  canClose = this._roleGuard.hasRole('EDIT_FISCAL_WEEK_CLOSED');
  canRunCashless = this._roleGuard.hasRole('EDIT_FISCAL_WEEK_CASHLESS');
  canRunCashlessTime = true;
  userID: number;
  validationProgress = 0;
  totalValidations = 0;

  statusUpdateMessage: string;
  statusUpdateMessageType: string;
  validateMessage: string;
  validateMessageType: string;
  closingMessage: string;
  closingMessageType: string;

  sortAsc : boolean = false;
  sortColumnName :string = '';

  intervalSubscrition: ISubscription;

  @ViewChild('basic') basic;
  basicModalOptions: NgbModalOptions = { centered: true };
  modalType = '';
  modalMessage = '';

  @ViewChild('closingError') closingError;
  notSuggestedMessage = '';
  modalOptions: NgbModalOptions = { backdrop: 'static', keyboard: false, centered: true };

  constructor(
    private _common: CommonService,
    private _security: SecurityService,
    private _roleGuard: RoleGuardService,
    private _operations: OperationsService,
    private _excel: ExcelService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _modal: NgbModal) {
      this._common.setCurrentPage('Fiscal Close Detail');
      this._common.operationGuideSubject.next('Operations Guide - OneVend - Fiscal Close.pdf');
    }

  ngOnInit() {
    const dstOffset = this._common.getDSTOffset(moment().toDate());
    const easterTime = moment().utc().subtract(dstOffset, 'hours');
    this.userID = this._security.getUserID();
    console.log('Hour: ' + easterTime.hour());
    if (easterTime.hour() < 10) {
      this.canRunCashlessTime = false;
    } else {
      this.canRunCashlessTime = true;
    }

    this.fiscalCalendarID = this._route.snapshot.params['fiscalCalendarID'];
    this._getDetail();

    // Refresh every 5 seconds
    this.intervalSubscrition = Observable.interval(5000).subscribe(() => {
      this._getDetail();
    });
  }

  ngOnDestroy() {
    this._common.operationGuideSubject.next(null);
    this.intervalSubscrition.unsubscribe();
  }

  _getDetail() {
    const operation = Number(this._common.operation.operationNo);
    this._operations.GetFiscalClosingDetail(operation, this.fiscalCalendarID).subscribe((data) => {
      data.lastValidationStartDateTime = this._common.parseDate(data.lastValidationStartDateTime);
      data.lastValidationEndDateTime = this._common.parseDate(data.lastValidationEndDateTime);
      data.sapExtractDate = this._common.parseDate(data.sapExtractDate);
      data.sapCloseDate = this._common.parseDate(data.sapCloseDate);
      this.closingDetails = data;

      this.disableValidate = (this.closingDetails.closeDate != null);
      this.disableClose    = (this.closingDetails.closeDate != null) || 
                             (this.closingDetails.lastValidationStartDateTime == null);
      this.disableCashless = (this.closingDetails.closeDate != null) || 
                             (this.closingDetails.lastValidationStartDateTime == null) ||
                             (this.closingDetails.cashlessInProcessInd);

      if (this.closingDetails.onOneWarehouse) {
        this.closingDetails.warehouses.forEach((warehouse) => {
          if (warehouse.status !== 'CLOSED') {
            this.disableClose = true;
          }
        });
      }

      if (this.closingDetails.validationState !== 'Open') {
        this.disableValidate = true;
        this.disableClose = true;
        this.disableCashless = true;
      }

    }, (error) => {
      console.log(error);
    });

    this._getValidationStatus();
  }

  _getValidationStatus() {
    const operation = Number(this._common.operation.operationNo);
    this._operations.GetValidationStatus(operation, this.fiscalCalendarID).subscribe((progress) => {
      this.validationProgress = progress.statusCode == null ? 0 : progress.statusCode;
      this.totalValidations = progress.totalValidations;
      const validationResults = progress.validationErrors;

      if (progress.statusCode === 0) { // Cashless running at status 0
        this.closingWarnings = [];
        this.closingErrors = [];
        this.closingIssuesForDisplay = [];
        this.cashlessRunning = true;
        this.loading = true;
        this.validationRan = false;
        this.clearAlertMessages();

      } else if (progress.statusCode === 200) { // Fiscal Week has been successfully closed
        this.closingMessage = 'The Operation for Fiscal Week ' +
        this._common.stringifyDate(this._common.parseDate(this.closingDetails.startDate)) +
        ' to ' +
        this._common.stringifyDate(this._common.parseDate(this.closingDetails.endDate)) +
        ' has been successfully closed.';
        this.closingMessageType = 'success';

      } else if (progress.statusCode === 418) {  // An error occured during a procedure run
        this.cashlessRunning = false;
        this.loading = false;
        this.validationRan = true;
        this.statusUpdateMessage = 'Error occured during validation, Please try again.';
        this.statusUpdateMessageType = 'danger';

      } else if (progress.statusCode > 0) { // Validation running, status represents current sproc # in list
        this.closingWarnings = [];
        this.closingErrors = [];
        this.closingIssuesForDisplay = [];
        this.cashlessRunning = false;
        this.loading = true;
        this.validationRan = false;
        this.clearAlertMessages();

      } else if (progress.statusCode == null
        && validationResults == null) { // No data for current fiscal week
        this.statusUpdateMessage = 'Validation info has not yet been stored for this Fiscal Week.';
        this.statusUpdateMessageType = 'dark';
        this.loading = false;

      } else if (progress.statusCode == null
                && (validationResults.errors && validationResults.warnings)
                && (validationResults.errors.length === 0 && validationResults.warnings.length === 0)) { // Validation complete with no errors or warnings
        this.statusUpdateMessage = 'Validation has successfully completed with no errors or warnings.';
        this.statusUpdateMessageType = 'success';
        this.closingWarnings = [];
        this.closingErrors = [];
        this.closingIssuesForDisplay = [];
        this.hasCashlessErrors = false;
        this.loading = false;
        this.validationRan = true;

      } else if (progress.statusCode == null
                && (validationResults.errors || validationResults.warnings)
                && (validationResults.errors.length > 0 || validationResults.warnings.length > 0)) { // Validation complete with 1+ errors or warnings
        this.closingWarnings = validationResults.warnings;
        this.closingErrors = validationResults.errors;
        this.buildDisplayItems();
        this.hasCashlessErrors = validationResults.hasCashlessErrors;
        this.validationRan = true;
        this.loading = false;
        this.validateMessage = 'Validation issues have occurred and are listed below';
        this.validateMessageType = 'dark';
      }
    }, (error) => {
      console.log(error);
      if (error.status === 401) { // Connection timeout
        this.statusUpdateMessage = 'Connection to the page has timed out, Please navigate away from page to refresh';
      } else {
        this.statusUpdateMessage = 'Error occured while attempting to update validation progress. Please contact VIS';
      }
      this.statusUpdateMessageType = 'danger';
      this.validationProgress = 0;
    });
  }

  private buildDisplayItems(){
    this.closingIssuesForDisplay = [];
    this.closingIssuesForDisplay = this.closingWarnings.concat(this.closingErrors);
    this.sort(this.sortAsc, this.sortColumnName); 
  }

  clearAlertMessages() {
    this.statusUpdateMessage = '';
    this.closingMessage = '';
    this.validateMessage = '';
  }

  back() {
    if (this.showBackWarning === true) {
      this.closingErrorMessage = 'If you navigate away now you will not be able to view the validation results.';
      this._modal.open(this.closingError, this.modalOptions).result.then((result) => {
        if (result.toString() === 'CONTINUE') {
          this._router.navigate(['/operations/fiscal-closing/']);
        } else {
          return;
        }
      });
    } else {
      this._router.navigate(['/operations/fiscal-closing/']);
    }
  }

  closeWeek() {
    if (this._roleGuard.hasRole('EDIT_FISCAL_WEEK_CLOSED')) {
      this.loading = true;
      this.closingDetails.lastValidationStartDateTime = new Date();
      this.closingDetails.lastValidationEndDateTime = null;
      this.closingDetails.validationState = 'Closing In-Progress';
      this.closingDetails.validationErrorsOnLastValidation = null;
      this.closingMessage = '';
      this.closingMessageType = '';
      const operationNo = this._common.operation.operationNo;
      const fiscalCalendarID = this.fiscalCalendarID;
      const onOneWarehouse = this.closingDetails.onOneWarehouse;
      const operationID = Number(this.closingDetails.operationID);
      this._operations.RequestFiscalWeekClose(operationNo, operationID, fiscalCalendarID, onOneWarehouse, this.userID).subscribe((data) => {
        this.hasCashlessErrors = data.hasCashlessErrors;
        this.closingWarnings = data.warnings;
        this.closingErrors = data.errors;
        this.validationRan = true;
        if (this.closingErrors.length === 0) {
          this.closingMessage = 'The Operation for Fiscal Week ' +
            this._common.stringifyDate(this._common.parseDate(this.closingDetails.startDate)) +
            ' to ' +
            this._common.stringifyDate(this._common.parseDate(this.closingDetails.endDate)) +
            ' has been successfully closed.';

            this.closingMessageType = 'success';
        } else {
          this.closingMessage = `Validation errors occurred during the closing process.
           Please review the errors and correct them or contact VIS.`;
          this.closingMessageType = 'warning';
        }
        this._getDetail();
        this.loading = false;
      }, (error) => {
        console.log(error);
        this.validationRan = true;
        this.closingMessage = 'Error occurred during the closing process. Please review the errors and correct them or contact VIS.';
        if (error && error.error && error.error.Message) {
          this.closingMessage += '<br /><br />' + error.error.Message;
        }
        this.closingMessageType = 'danger';
        this._getDetail();
        this.loading = false;
      });
    }
  }

  exportToExcel() {
    if (this.closingErrors && this.closingErrors.length > 0) {
      const date = this.closingDetails.endDate.toString().substr(0, this.closingDetails.endDate.toString().indexOf('T'));
      const fileName = 'ValidationIssues_' + this._common.operation.name + '_' + date;
      this.closingErrors.forEach((error) => {
        delete error.fiscalDate;
      });
      this._excel.exportAsExcelFile(this.closingErrors, fileName);
    } else {
      this.validateMessageType = 'warning';
      this.validateMessage = 'Please run Validation first to Export errors.';
      this._modal.open(this.basic, this.basicModalOptions);
    }
  }

  runCashless() {
    this.cashlessRunning = true;
    const operation = Number(this._common.operation.operationNo);
    const operationID = Number(this.closingDetails.operationID);
    const fiscalCalendarID = this.fiscalCalendarID;
    const onOneWarehouse = this.closingDetails.onOneWarehouse;
    this._operations.RunCashless(operation, operationID, fiscalCalendarID, onOneWarehouse, this.userID).subscribe((result) => {
      this.hasCashlessErrors = result.hasCashlessErrors;
      this.cashlessRunning = false;
      this.cashlessRan = true;
      this.closingWarnings = result.warnings;
      this.closingErrors = result.errors;
    }, (error) => {
      this.cashlessRunning = false;
      this.cashlessRan = true;
    });
  }

  sort(sortAsc, columnName) {
    this.sortAsc = sortAsc;
    this.sortColumnName = columnName;

    if (this.sortColumnName != '') {
      this.closingIssuesForDisplay.sort(this._common.dynamicSort(sortAsc, columnName));
    }
  }

  validate() {
    if (this._roleGuard.hasRole('VIEW_FISCAL_WEEK_CLOSED')) {
      this.loading = true;
      this.closingDetails.lastValidationStartDateTime = new Date();
      this.closingDetails.lastValidationEndDateTime = null;
      this.closingDetails.validationState = 'Validating';
      this.closingDetails.validationErrorsOnLastValidation = null;
      this.validateMessage = '';
      this.validateMessageType = '';
      const operationNo = Number(this._common.operation.operationNo);
      const operationID = Number(this.closingDetails.operationID);
      const fiscalCalendarID = this.fiscalCalendarID;
      const onOneWarehouse = this.closingDetails.onOneWarehouse;
      this._operations.ValidateFiscalWeek(operationNo, operationID, fiscalCalendarID, onOneWarehouse, this.userID).subscribe((data) => {

      }, (error) => {
        console.log(error);
        this.validationRan = true;
        this.validateMessage = 'Error occured during the validation process. Please review the errors and correct them or contact VIS.';
        if (error && error.error && error.error.Message) {
          this.validateMessage += '<br /><br />' + error.error.Message;
        }
        this.validateMessageType = 'danger';
        this._getDetail();
        this.loading = false;
        this.showBackWarning = false;
      });
    }
  }
}
