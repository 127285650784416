import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../../core/common-service/common.service';
import { AppsettingsProvider } from '../../core/appsettings.provider';
import { MachineDeviceRpcUpdate, MachineDeviceSearchItem, MachineDeviceSearchMetadata, MachineDeviceSearchRequest, MachineDeviceSearchResponse, MachineDeviceSelectionPriceRequest, PogDexDiscrepancySearchItem, PogDexDiscrepancySearchMetadata, PogDexDiscrepancySearchRequest, PogDexDiscrepancySearchResponse, RPCMachineDeviceItemsResponse, RPCTrackStatusItem, RPCTrackStatusResponse, RPCTrackStatusSaveRequest, RPCTrackStatusSaveResponse, RpcTrackStatusSearchRequest } from './remote-price-change.model';
import { FilterBarService } from '../../shared/filter-bar-service/filter-bar.service';
import { Subject } from 'rxjs';

@Injectable()
export class RemotePriceChangeService {

  appSettings: any;
  previousSearch: MachineDeviceSearchItem;

  machineDeviceSearchItemList: MachineDeviceSearchItem[];
  machineDeviceSearchItemSubject: Subject<MachineDeviceSearchItem[]> = new Subject<MachineDeviceSearchItem[]>();
  machineDeviceSearchMetadataSubject:  Subject<MachineDeviceSearchMetadata> = new Subject<MachineDeviceSearchMetadata>();

  pogDexDiscrepancySearchItemList: PogDexDiscrepancySearchItem[];
  pogDexDiscrepancySearchItemSubject: Subject<PogDexDiscrepancySearchItem[]> = new Subject<PogDexDiscrepancySearchItem[]>();
  pogDexDiscrepancySearchMetadataSubject: Subject<PogDexDiscrepancySearchMetadata> = new Subject<PogDexDiscrepancySearchMetadata>();

  httpErrorResponseSubject: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

  // RPC Maintenance events
  @Output() onRPCUpdateSuccessEventEmitter = new EventEmitter<MachineDeviceSearchResponse>();
  @Output() onRPCUpdateFailureEventEmitter = new EventEmitter();

  // RPC Price Discrepancies events
  @Output() onRPCPriceDiscrepanciesDataReadyEventEmitter = new EventEmitter();
  @Output() onRPCRequestBeginEventEmitter = new EventEmitter();
  @Output() onRPCRequestEndSuccessEventEmitter = new EventEmitter();
  @Output() onRPCRequestEndFailureEventEmitter = new EventEmitter();
  @Output() onRPCRequestChangeEventEmitter = new EventEmitter<PogDexDiscrepancySearchItem>();

  constructor(private _http: HttpClient,
    private _common: CommonService,
    private appSettingsProvider: AppsettingsProvider,
    private _filterBarService  : FilterBarService) {
      this.appSettings = this.appSettingsProvider.getSettings();
    }

  Initialize() {
      const costCenterID = Number(this._common.operation.operationNo);
  
      // Get all filter lists and the result set from MachineService API
      this._http.get<MachineDeviceSearchResponse>(
        this.appSettings.urlLocations.deviceManagementURL + 'machinedevicesearchfilter/' + costCenterID)
        .subscribe((data: MachineDeviceSearchResponse) => {       
         const routeList = data.routeLineItems;
         this._filterBarService.nonFilteredRoutes = routeList;
         this._filterBarService.routes.next(routeList);
        },
        (error)=>{
          console.log(error);
         });
  }
  
  onError(error) {
    console.log(error);
    let httpErrorResponse:HttpErrorResponse = error;
    if (httpErrorResponse) {
      this.httpErrorResponseSubject.next(httpErrorResponse);
    }
  }
  
  Search(searchRequest: MachineDeviceSearchRequest) {
    this._http.post<MachineDeviceSearchResponse>(this.appSettings.urlLocations.deviceManagementURL + 'machinedevicesearch', searchRequest)
      .subscribe((data: MachineDeviceSearchResponse) => {
        this.machineDeviceSearchItemSubject.next(data.items.slice());
        this.machineDeviceSearchMetadataSubject.next(data.metadata);
      },
      (error)=> {
        this.onError(error);
      });
  }

  GetMachineDeviceSearch(searchRequest: MachineDeviceSearchRequest) {
    return this._http.post<MachineDeviceSearchResponse>(this.appSettings.urlLocations.deviceManagementURL + 'machinedevicesearch', searchRequest);
  }

  PogDexDiscrepancySearch(searchRequest: PogDexDiscrepancySearchRequest) {
    this._http.post<PogDexDiscrepancySearchResponse>(this.appSettings.urlLocations.deviceManagementURL + 'pogdexdiscrepancysearch', searchRequest)
      .subscribe((data: PogDexDiscrepancySearchResponse) => {
        this.pogDexDiscrepancySearchItemSubject.next(data.items.slice());
        this.pogDexDiscrepancySearchMetadataSubject.next(data.metadata);
        this.onRPCPriceDiscrepanciesDataReadyEventEmitter.emit();
      },
      (error)=> {
        this.onError(error);
      });
  }

  sendUpdates(updateList: MachineDeviceRpcUpdate[]){
    this._http.post<MachineDeviceSearchResponse>(this.appSettings.urlLocations.deviceManagementURL + 'machinedeviceupdate', updateList)
      .subscribe((data: MachineDeviceSearchResponse) => {
        this.onRPCUpdateSuccessEventEmitter.emit(data);
      },
      (error)=> {
        this.onError(error);
        this.onRPCUpdateFailureEventEmitter.emit();
      });
  }

  sendMachineDeviceSelectionPriceRequest(itemsToSend:Array<PogDexDiscrepancySearchItem>, indexToSend:number) {
    if (indexToSend >= itemsToSend.length) {
          // If there are no remaining requests, emit the end-with-success event
          this.onRPCRequestEndSuccessEventEmitter.emit();
    } else {
      let item = itemsToSend[indexToSend];
      indexToSend = indexToSend + 1;
      let request = new MachineDeviceSelectionPriceRequest();
      request.setFromItem(item);
      this._http.post<MachineDeviceSearchResponse>(this.appSettings.urlLocations.deviceManagementURL + 'machinedeviceselectionprice', request)
        .subscribe((data: MachineDeviceSearchResponse) => {
          item.statusCode = "IN_PROCESS";
          this.onRPCRequestChangeEventEmitter.emit(item);
          console.log(`Sent price change request for machine number ${item.machineNumber}`);
          this.sendMachineDeviceSelectionPriceRequest(itemsToSend, indexToSend);
        },
        (error)=> {
          item.statusCode = "ERROR";
          this.onRPCRequestChangeEventEmitter.emit(item);
          let errorMessage = `Failed to send price change request for machine number ${item.machineNumber}.`;
          if (error.error != null && error.error != undefined) {
            errorMessage = errorMessage + ` ${error.error.title}.`            
          }
          console.log(errorMessage);
          this.onRPCRequestEndFailureEventEmitter.emit();
          this.onError(error);
          this.sendMachineDeviceSelectionPriceRequest(itemsToSend, indexToSend);
        });
    }
  }

  sendMachineDeviceSelectionPriceRequests(itemsToSend:Array<PogDexDiscrepancySearchItem>) {
    this.onRPCRequestBeginEventEmitter.emit();    
    // Get the first request and provide it recursively to sendMachineDeviceSelectionPriceRequest with array of the remaining requests
    this.sendMachineDeviceSelectionPriceRequest(itemsToSend, 0);
  }

  // --------------------------------------------------------------------------
  // RPC Tracking Status
  GetRPCTrackStatusItems(searchRequest: RpcTrackStatusSearchRequest) {
    return this._http.post<RPCTrackStatusResponse>(this.appSettings.urlLocations.deviceManagementURL + 'rpctrackstatus', searchRequest);
  }

  PutRPCTrackStatusItems(items:RPCTrackStatusItem[], userID:number) {
    const request = new RPCTrackStatusSaveRequest();
    request.items = items;
    request.userId = userID;
    return this._http.put<RPCTrackStatusSaveResponse>(`${this.appSettings.urlLocations.deviceManagementURL}RPCTrackStatus`, request);
  }
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // RPC Compatibility
  GetRPCMachineDeviceConfigItems(costCenter: number, pageNum:number, pageSize:number, sortColumn:string, sortDirection:string) {
    return this._http.get<RPCMachineDeviceItemsResponse>(`${this.appSettings.urlLocations.deviceManagementURL}RPCMachineDeviceConfig?costCenter=${costCenter}&pageNum=${pageNum}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`);
  }
  // --------------------------------------------------------------------------
}
